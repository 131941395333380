import { emptyApi as api } from "./emptyApi";
export const addTagTypes = [
  "Chat",
  "Collections",
  "Customers",
  "Extensions",
  "Login",
  "Sources",
  "Test",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getV1ChatMessageLog: build.query<
        GetV1ChatMessageLogApiResponse,
        GetV1ChatMessageLogApiArg
      >({
        query: () => ({ url: `/v1/chat/message_log/` }),
        providesTags: ["Chat"],
      }),
      postV1ChatPrompt: build.mutation<
        PostV1ChatPromptApiResponse,
        PostV1ChatPromptApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/chat/prompt/`,
          method: "POST",
          body: queryArg.getChatRequest,
        }),
        invalidatesTags: ["Chat"],
      }),
      getV1ChatRetrieve: build.query<
        GetV1ChatRetrieveApiResponse,
        GetV1ChatRetrieveApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/chat/retrieve/`,
          params: {
            query: queryArg.query,
            collection_name: queryArg.collectionName,
          },
        }),
        providesTags: ["Chat"],
      }),
      postV1ChatUserMessages: build.mutation<
        PostV1ChatUserMessagesApiResponse,
        PostV1ChatUserMessagesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/chat/user_messages/`,
          method: "POST",
          body: queryArg.getChatRequest,
        }),
        invalidatesTags: ["Chat"],
      }),
      getV1Collections: build.query<
        GetV1CollectionsApiResponse,
        GetV1CollectionsApiArg
      >({
        query: () => ({ url: `/v1/collections/` }),
        providesTags: ["Collections"],
      }),
      postV1Collections: build.mutation<
        PostV1CollectionsApiResponse,
        PostV1CollectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/collections/`,
          method: "POST",
          body: queryArg.collectionCreate,
        }),
        invalidatesTags: ["Collections"],
      }),
      deleteV1CollectionsByCollectionId: build.mutation<
        DeleteV1CollectionsByCollectionIdApiResponse,
        DeleteV1CollectionsByCollectionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/collections/${queryArg.collectionId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Collections"],
      }),
      getV1CollectionsByCollectionId: build.query<
        GetV1CollectionsByCollectionIdApiResponse,
        GetV1CollectionsByCollectionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/collections/${queryArg.collectionId}`,
        }),
        providesTags: ["Collections"],
      }),
      putV1CollectionsByCollectionId: build.mutation<
        PutV1CollectionsByCollectionIdApiResponse,
        PutV1CollectionsByCollectionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/collections/${queryArg.collectionId}`,
          method: "PUT",
          body: queryArg.collectionUpdate,
        }),
        invalidatesTags: ["Collections"],
      }),
      getV1Customers: build.query<
        GetV1CustomersApiResponse,
        GetV1CustomersApiArg
      >({
        query: () => ({ url: `/v1/customers/` }),
        providesTags: ["Customers"],
      }),
      postV1Customers: build.mutation<
        PostV1CustomersApiResponse,
        PostV1CustomersApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/customers/`,
          method: "POST",
          body: queryArg.customerCreate,
        }),
        invalidatesTags: ["Customers"],
      }),
      getV1CustomersAll: build.query<
        GetV1CustomersAllApiResponse,
        GetV1CustomersAllApiArg
      >({
        query: () => ({ url: `/v1/customers/all` }),
        providesTags: ["Customers"],
      }),
      getV1CustomersCompanyByCompanyName: build.query<
        GetV1CustomersCompanyByCompanyNameApiResponse,
        GetV1CustomersCompanyByCompanyNameApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/customers/company/${queryArg.companyName}`,
        }),
        providesTags: ["Customers"],
      }),
      postV1CustomersFirebase: build.mutation<
        PostV1CustomersFirebaseApiResponse,
        PostV1CustomersFirebaseApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/customers/firebase`,
          method: "POST",
          body: queryArg.firebaseCustomerCreate,
        }),
        invalidatesTags: ["Customers"],
      }),
      deleteV1CustomersByCustomerId: build.mutation<
        DeleteV1CustomersByCustomerIdApiResponse,
        DeleteV1CustomersByCustomerIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/customers/${queryArg.customerId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Customers"],
      }),
      getV1CustomersByCustomerId: build.query<
        GetV1CustomersByCustomerIdApiResponse,
        GetV1CustomersByCustomerIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1/customers/${queryArg.customerId}` }),
        providesTags: ["Customers"],
      }),
      patchV1CustomersByCustomerId: build.mutation<
        PatchV1CustomersByCustomerIdApiResponse,
        PatchV1CustomersByCustomerIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/customers/${queryArg.customerId}`,
          method: "PATCH",
          body: queryArg.customerUpdate,
        }),
        invalidatesTags: ["Customers"],
      }),
      deleteV1Extensions: build.mutation<
        DeleteV1ExtensionsApiResponse,
        DeleteV1ExtensionsApiArg
      >({
        query: () => ({ url: `/v1/extensions/`, method: "DELETE" }),
        invalidatesTags: ["Extensions"],
      }),
      getV1Extensions: build.query<
        GetV1ExtensionsApiResponse,
        GetV1ExtensionsApiArg
      >({
        query: () => ({ url: `/v1/extensions/` }),
        providesTags: ["Extensions"],
      }),
      postV1Extensions: build.mutation<
        PostV1ExtensionsApiResponse,
        PostV1ExtensionsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/extensions/`,
          method: "POST",
          body: queryArg.extensionCreate,
        }),
        invalidatesTags: ["Extensions"],
      }),
      putV1Extensions: build.mutation<
        PutV1ExtensionsApiResponse,
        PutV1ExtensionsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/extensions/`,
          method: "PUT",
          body: queryArg.extensionUpdate,
        }),
        invalidatesTags: ["Extensions"],
      }),
      getV1ExtensionsDownloadBySpecialObjectName: build.query<
        GetV1ExtensionsDownloadBySpecialObjectNameApiResponse,
        GetV1ExtensionsDownloadBySpecialObjectNameApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/extensions/download/${queryArg.specialObjectName}`,
        }),
        providesTags: ["Extensions"],
      }),
      postV1ExtensionsPublish: build.mutation<
        PostV1ExtensionsPublishApiResponse,
        PostV1ExtensionsPublishApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/extensions/publish`,
          method: "POST",
          body: queryArg.extensionPublish,
        }),
        invalidatesTags: ["Extensions"],
      }),
      getV1ExtensionsTemplates: build.query<
        GetV1ExtensionsTemplatesApiResponse,
        GetV1ExtensionsTemplatesApiArg
      >({
        query: () => ({ url: `/v1/extensions/templates` }),
        providesTags: ["Extensions"],
      }),
      postV1Login: build.mutation<PostV1LoginApiResponse, PostV1LoginApiArg>({
        query: (queryArg) => ({
          url: `/v1/login/`,
          method: "POST",
          body: queryArg.login,
        }),
        invalidatesTags: ["Login"],
      }),
      getV1Sources: build.query<GetV1SourcesApiResponse, GetV1SourcesApiArg>({
        query: () => ({ url: `/v1/sources/` }),
        providesTags: ["Sources"],
      }),
      postV1SourcesDocument: build.mutation<
        PostV1SourcesDocumentApiResponse,
        PostV1SourcesDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/document`,
          method: "POST",
          body: queryArg.documentSourceCreate,
        }),
        invalidatesTags: ["Sources"],
      }),
      putV1SourcesDocumentBySourceId: build.mutation<
        PutV1SourcesDocumentBySourceIdApiResponse,
        PutV1SourcesDocumentBySourceIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/document/${queryArg.sourceId}`,
          method: "PUT",
          body: queryArg.documentSourceUpdate,
        }),
        invalidatesTags: ["Sources"],
      }),
      postV1SourcesSuggestion: build.mutation<
        PostV1SourcesSuggestionApiResponse,
        PostV1SourcesSuggestionApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/suggestion`,
          method: "POST",
          body: queryArg.suggestionSourceCreate,
        }),
        invalidatesTags: ["Sources"],
      }),
      putV1SourcesSuggestionBySourceId: build.mutation<
        PutV1SourcesSuggestionBySourceIdApiResponse,
        PutV1SourcesSuggestionBySourceIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/suggestion/${queryArg.sourceId}`,
          method: "PUT",
          body: queryArg.suggestionSourceUpdate,
        }),
        invalidatesTags: ["Sources"],
      }),
      postV1SourcesWebsite: build.mutation<
        PostV1SourcesWebsiteApiResponse,
        PostV1SourcesWebsiteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/website`,
          method: "POST",
          body: queryArg.websiteSourceCreate,
        }),
        invalidatesTags: ["Sources"],
      }),
      getV1SourcesWebsiteCrawl: build.query<
        GetV1SourcesWebsiteCrawlApiResponse,
        GetV1SourcesWebsiteCrawlApiArg
      >({
        query: () => ({ url: `/v1/sources/website/crawl` }),
        providesTags: ["Sources"],
      }),
      postV1SourcesWebsiteCrawl: build.mutation<
        PostV1SourcesWebsiteCrawlApiResponse,
        PostV1SourcesWebsiteCrawlApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/website/crawl`,
          method: "POST",
          body: queryArg.webCrawlFilterInput,
          params: { url: queryArg.url },
        }),
        invalidatesTags: ["Sources"],
      }),
      getV1SourcesWebsiteCrawlClearByJobId: build.query<
        GetV1SourcesWebsiteCrawlClearByJobIdApiResponse,
        GetV1SourcesWebsiteCrawlClearByJobIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/website/crawl/clear/${queryArg.jobId}`,
        }),
        providesTags: ["Sources"],
      }),
      postV1SourcesWebsiteCrawlConsume: build.mutation<
        PostV1SourcesWebsiteCrawlConsumeApiResponse,
        PostV1SourcesWebsiteCrawlConsumeApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/website/crawl/consume`,
          method: "POST",
          body: queryArg.webCrawlConsumeInput,
        }),
        invalidatesTags: ["Sources"],
      }),
      getV1SourcesWebsiteCrawlProgressByJobId: build.query<
        GetV1SourcesWebsiteCrawlProgressByJobIdApiResponse,
        GetV1SourcesWebsiteCrawlProgressByJobIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/website/crawl/progress/${queryArg.jobId}`,
        }),
        providesTags: ["Sources"],
      }),
      postV1SourcesWebsiteScrape: build.mutation<
        PostV1SourcesWebsiteScrapeApiResponse,
        PostV1SourcesWebsiteScrapeApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/website/scrape`,
          method: "POST",
          body: queryArg.webScrapeConsumeInput,
        }),
        invalidatesTags: ["Sources"],
      }),
      postV1SourcesWebsites: build.mutation<
        PostV1SourcesWebsitesApiResponse,
        PostV1SourcesWebsitesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/websites`,
          method: "POST",
          body: queryArg.multiWebsiteSourceCreate,
        }),
        invalidatesTags: ["Sources"],
      }),
      getV1SourcesWebsitesScrape: build.query<
        GetV1SourcesWebsitesScrapeApiResponse,
        GetV1SourcesWebsitesScrapeApiArg
      >({
        query: () => ({ url: `/v1/sources/websites/scrape` }),
        providesTags: ["Sources"],
      }),
      deleteV1SourcesBySourceId: build.mutation<
        DeleteV1SourcesBySourceIdApiResponse,
        DeleteV1SourcesBySourceIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/${queryArg.sourceId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Sources"],
      }),
      getV1SourcesBySourceId: build.query<
        GetV1SourcesBySourceIdApiResponse,
        GetV1SourcesBySourceIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1/sources/${queryArg.sourceId}` }),
        providesTags: ["Sources"],
      }),
      getV1SourcesBySourceIdDownload: build.query<
        GetV1SourcesBySourceIdDownloadApiResponse,
        GetV1SourcesBySourceIdDownloadApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/sources/${queryArg.sourceId}/download`,
        }),
        providesTags: ["Sources"],
      }),
      postV1Test: build.mutation<PostV1TestApiResponse, PostV1TestApiArg>({
        query: () => ({ url: `/v1/test/`, method: "POST" }),
        invalidatesTags: ["Test"],
      }),
      postV1TestBucket: build.mutation<
        PostV1TestBucketApiResponse,
        PostV1TestBucketApiArg
      >({
        query: () => ({ url: `/v1/test/bucket`, method: "POST" }),
        invalidatesTags: ["Test"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as layerApi };
export type GetV1ChatMessageLogApiResponse =
  /** status 200 Successful response */ MessageLog[];
export type GetV1ChatMessageLogApiArg = void;
export type PostV1ChatPromptApiResponse =
  /** status 200 Successful response */ GetPromptResponse;
export type PostV1ChatPromptApiArg = {
  getChatRequest: GetChatRequest;
};
export type GetV1ChatRetrieveApiResponse =
  /** status 200 Successful response */ SearchResponse[];
export type GetV1ChatRetrieveApiArg = {
  query: string;
  collectionName?: "Main" | "Suggestions" | "Code";
};
export type PostV1ChatUserMessagesApiResponse =
  /** status 200 Successful response */ ChatCompletionResponse;
export type PostV1ChatUserMessagesApiArg = {
  getChatRequest: GetChatRequest;
};
export type GetV1CollectionsApiResponse =
  /** status 200 Successful response */ Collection[];
export type GetV1CollectionsApiArg = void;
export type PostV1CollectionsApiResponse =
  /** status 201 Successful response */ Collection;
export type PostV1CollectionsApiArg = {
  collectionCreate: CollectionCreate;
};
export type DeleteV1CollectionsByCollectionIdApiResponse =
  /** status 204 Successful response */ void;
export type DeleteV1CollectionsByCollectionIdApiArg = {
  collectionId: number;
};
export type GetV1CollectionsByCollectionIdApiResponse =
  /** status 200 Successful response */ Collection;
export type GetV1CollectionsByCollectionIdApiArg = {
  collectionId: number;
};
export type PutV1CollectionsByCollectionIdApiResponse =
  /** status 200 Successful response */ Collection;
export type PutV1CollectionsByCollectionIdApiArg = {
  collectionId: number;
  collectionUpdate: CollectionUpdate;
};
export type GetV1CustomersApiResponse =
  /** status 200 Successful response */ Customer;
export type GetV1CustomersApiArg = void;
export type PostV1CustomersApiResponse =
  /** status 201 Successful response */ Customer;
export type PostV1CustomersApiArg = {
  customerCreate: CustomerCreate;
};
export type GetV1CustomersAllApiResponse =
  /** status 200 Successful response */ Customer[];
export type GetV1CustomersAllApiArg = void;
export type GetV1CustomersCompanyByCompanyNameApiResponse =
  /** status 200 Successful response */ any;
export type GetV1CustomersCompanyByCompanyNameApiArg = {
  companyName: string;
};
export type PostV1CustomersFirebaseApiResponse =
  /** status 201 Successful response */ FirebaseCustomerCreateResponse;
export type PostV1CustomersFirebaseApiArg = {
  firebaseCustomerCreate: FirebaseCustomerCreate;
};
export type DeleteV1CustomersByCustomerIdApiResponse =
  /** status 204 Successful response */ void;
export type DeleteV1CustomersByCustomerIdApiArg = {
  customerId: number;
};
export type GetV1CustomersByCustomerIdApiResponse =
  /** status 200 Successful response */ Customer;
export type GetV1CustomersByCustomerIdApiArg = {
  customerId: number;
};
export type PatchV1CustomersByCustomerIdApiResponse =
  /** status 200 Successful response */ Customer;
export type PatchV1CustomersByCustomerIdApiArg = {
  customerId: number;
  customerUpdate: CustomerUpdate;
};
export type DeleteV1ExtensionsApiResponse =
  /** status 200 Successful response */ Extension;
export type DeleteV1ExtensionsApiArg = void;
export type GetV1ExtensionsApiResponse =
  /** status 200 Successful response */ Extension;
export type GetV1ExtensionsApiArg = void;
export type PostV1ExtensionsApiResponse =
  /** status 201 Successful response */ Extension;
export type PostV1ExtensionsApiArg = {
  extensionCreate: ExtensionCreate;
};
export type PutV1ExtensionsApiResponse =
  /** status 200 Successful response */ Extension;
export type PutV1ExtensionsApiArg = {
  extensionUpdate: ExtensionUpdate;
};
export type GetV1ExtensionsDownloadBySpecialObjectNameApiResponse =
  /** status 200 Successful response */ any;
export type GetV1ExtensionsDownloadBySpecialObjectNameApiArg = {
  specialObjectName: string;
};
export type PostV1ExtensionsPublishApiResponse =
  /** status 200 Successful response */ any;
export type PostV1ExtensionsPublishApiArg = {
  extensionPublish: ExtensionPublish;
};
export type GetV1ExtensionsTemplatesApiResponse =
  /** status 200 Successful response */ ExtensionTemplateList;
export type GetV1ExtensionsTemplatesApiArg = void;
export type PostV1LoginApiResponse =
  /** status 200 Successful response */ AccessToken;
export type PostV1LoginApiArg = {
  login: Login;
};
export type GetV1SourcesApiResponse =
  /** status 200 Successful response */ Source[];
export type GetV1SourcesApiArg = void;
export type PostV1SourcesDocumentApiResponse =
  /** status 200 Successful response */ DocumentSource;
export type PostV1SourcesDocumentApiArg = {
  documentSourceCreate: DocumentSourceCreate;
};
export type PutV1SourcesDocumentBySourceIdApiResponse =
  /** status 200 Successful response */ DocumentSource;
export type PutV1SourcesDocumentBySourceIdApiArg = {
  sourceId: number;
  documentSourceUpdate: DocumentSourceUpdate;
};
export type PostV1SourcesSuggestionApiResponse =
  /** status 200 Successful response */ SuggestionSource;
export type PostV1SourcesSuggestionApiArg = {
  suggestionSourceCreate: SuggestionSourceCreate;
};
export type PutV1SourcesSuggestionBySourceIdApiResponse =
  /** status 200 Successful response */ SuggestionSource;
export type PutV1SourcesSuggestionBySourceIdApiArg = {
  sourceId: number;
  suggestionSourceUpdate: SuggestionSourceUpdate;
};
export type PostV1SourcesWebsiteApiResponse =
  /** status 200 Successful response */ WebsiteSource;
export type PostV1SourcesWebsiteApiArg = {
  websiteSourceCreate: WebsiteSourceCreate;
};
export type GetV1SourcesWebsiteCrawlApiResponse =
  /** status 200 Successful response */ WebCrawlActiveJobsOutput;
export type GetV1SourcesWebsiteCrawlApiArg = void;
export type PostV1SourcesWebsiteCrawlApiResponse =
  /** status 200 Successful response */ WebCrawlResponse;
export type PostV1SourcesWebsiteCrawlApiArg = {
  url?: string;
  webCrawlFilterInput: WebCrawlFilterInput;
};
export type GetV1SourcesWebsiteCrawlClearByJobIdApiResponse =
  /** status 204 Successful response */ void;
export type GetV1SourcesWebsiteCrawlClearByJobIdApiArg = {
  jobId: string;
};
export type PostV1SourcesWebsiteCrawlConsumeApiResponse =
  /** status 204 Successful response */ void;
export type PostV1SourcesWebsiteCrawlConsumeApiArg = {
  webCrawlConsumeInput: WebCrawlConsumeInput;
};
export type GetV1SourcesWebsiteCrawlProgressByJobIdApiResponse =
  /** status 200 Successful response */ WebCrawlProgressOutput;
export type GetV1SourcesWebsiteCrawlProgressByJobIdApiArg = {
  jobId: string;
};
export type PostV1SourcesWebsiteScrapeApiResponse =
  /** status 204 Successful response */ void;
export type PostV1SourcesWebsiteScrapeApiArg = {
  webScrapeConsumeInput: WebScrapeConsumeInput;
};
export type PostV1SourcesWebsitesApiResponse =
  /** status 200 Successful response */ Generated;
export type PostV1SourcesWebsitesApiArg = {
  multiWebsiteSourceCreate: MultiWebsiteSourceCreate;
};
export type GetV1SourcesWebsitesScrapeApiResponse =
  /** status 200 Successful response */ WebScrapeResponse;
export type GetV1SourcesWebsitesScrapeApiArg = void;
export type DeleteV1SourcesBySourceIdApiResponse =
  /** status 204 Successful response */ void;
export type DeleteV1SourcesBySourceIdApiArg = {
  sourceId: number;
};
export type GetV1SourcesBySourceIdApiResponse =
  /** status 200 Successful response */ Source;
export type GetV1SourcesBySourceIdApiArg = {
  sourceId: number;
};
export type GetV1SourcesBySourceIdDownloadApiResponse =
  /** status 200 Successful response */ Blob;
export type GetV1SourcesBySourceIdDownloadApiArg = {
  sourceId: number;
};
export type PostV1TestApiResponse = /** status 200 Successful response */ Test;
export type PostV1TestApiArg = void;
export type PostV1TestBucketApiResponse =
  /** status 200 Successful response */ Test;
export type PostV1TestBucketApiArg = void;
export type MessageLog = {
  created_at?: string;
  customer_id: number;
  id?: number;
  ip: string;
  message: string;
  updated_at?: string;
};
export type HttpError = {
  detail?: object;
  message?: string;
};
export type Citation = {
  name: string;
  url: string;
};
export type Reference = {
  id: string;
  name: string;
  value: string;
};
export type Message = {
  citation?: Citation;
  content: string;
  reference?: Reference;
  role: "assistant" | "user" | "system";
};
export type GetPromptResponse = {
  messages: Message[];
};
export type ValidationError = {
  detail?: {
    "<location>"?: {
      "<field_name>"?: string[];
    };
  };
  message?: string;
};
export type CleanedMessage = {
  content: string;
  role: "assistant" | "user" | "system";
};
export type GetChatRequest = {
  history?: CleanedMessage[];
  query: string;
  references?: Reference[] | null;
  token_limit?: number | null;
};
export type SearchResponse = {
  name: string;
  snippet: string;
  url: string | null;
};
export type ChatCompletionResponse = {
  citations: Citation[];
  message: string;
};
export type Collection = {
  created_at?: string;
  description?: string | null;
  id?: number;
  name: string;
  updated_at?: string;
};
export type CollectionCreate = {
  description?: string | null;
  name: string;
};
export type CollectionUpdate = {
  description?: string | null;
  name: string;
};
export type Customer = {
  api_key?: string;
  company_name: string;
  created_at?: string;
  customer_api_key?: string;
  email: string;
  id?: number;
  updated_at?: string;
};
export type CustomerCreate = {
  company_name: string;
  email: string;
};
export type FirebaseCustomerCreateResponse = {
  company_name: string;
  email: string;
  id?: number;
};
export type FirebaseCustomerCreate = {
  company_name?: string;
  firebase_token?: string;
};
export type CustomerUpdate = {
  api_key?: string;
  company_name?: string;
  customer_api_key?: string;
};
export type Extension = {
  created_at?: string;
  description: string;
  display_name: string;
  id?: number;
  logo_url: string;
  marketplace_url: string;
  name: string;
  owner: number;
  participant_description: string;
  participant_name: string;
  pending_publish?: boolean;
  readme_url: string;
  updated_at?: string;
  version?: string;
};
export type ExtensionCreate = {
  description: string;
  display_name: string;
  logo: Blob;
  name: string;
  participant_description: string;
  participant_name: string;
  readme: Blob;
};
export type ExtensionUpdate = {
  description?: string;
  display_name?: string;
  logo?: Blob;
  participant_description?: string;
  participant_name?: string;
  readme?: Blob;
  version?: string;
};
export type ExtensionPublish = {
  template_name?: string | null;
};
export type ExtensionTemplateList = {
  template_names: string[];
};
export type AccessToken = {
  access_token: string;
};
export type Login = {
  password: string;
  username: string;
};
export type DocumentSource = {
  collection_id: number;
  created_at?: string;
  extension: string;
  filename: string;
  id: number;
  name: string;
  type: string;
  updated_at?: string;
};
export type SuggestionSource = {
  answer: string;
  collection_id: number;
  created_at?: string;
  id: number;
  name: string;
  question: string;
  type: string;
  updated_at?: string;
};
export type WebsiteSource = {
  collection_id: number;
  created_at?: string;
  document?: string | null;
  id: number;
  name: string;
  type: string;
  updated_at?: string;
  url: string;
};
export type Source =
  | ({
      type: "document";
    } & DocumentSource)
  | ({
      type: "suggestion";
    } & SuggestionSource)
  | ({
      type: "website";
    } & WebsiteSource);
export type DocumentSourceCreate = {
  collection_name?: "MAIN" | "SUGGESTIONS" | "CODE";
  file: Blob;
  name: string;
};
export type DocumentSourceUpdate = {
  file?: Blob;
  name?: string;
};
export type SuggestionSourceCreate = {
  answer: string;
  name: string;
  question: string;
};
export type SuggestionSourceUpdate = {
  answer?: string;
  name?: string;
  question?: string;
};
export type WebsiteSourceCreate = {
  collection_id?: number;
  name?: string;
  url: string;
};
export type WebCrawlActiveJobsOutput = {
  job_ids: string[];
};
export type WebCrawlResponse = {
  job_id?: string;
};
export type WebCrawlFilterInput = {
  url_filters?: string[] | null;
};
export type WebCrawlConsumeInput = {
  customer_id?: number;
  job_key?: string;
  url?: string;
  url_filters?: string[] | null;
};
export type WebCrawlProgressOutput = {
  done: boolean;
  failed_urls: string[];
  successful_urls: string[];
  to_visit_urls: string[];
};
export type WebScrapeConsumeInput = {
  collection_id?: number | null;
  customer_id?: number;
  job_key?: string;
  name?: string | null;
  url?: string;
};
export type Generated = {
  job_id?: string;
};
export type MultiWebsiteSourceCreate = {
  collection_id?: number;
  urls: string[];
};
export type WebScrapeResponse = {
  active: number;
};
export type Test = {
  status: string;
};
export const {
  useGetV1ChatMessageLogQuery,
  useLazyGetV1ChatMessageLogQuery,
  usePostV1ChatPromptMutation,
  useGetV1ChatRetrieveQuery,
  useLazyGetV1ChatRetrieveQuery,
  usePostV1ChatUserMessagesMutation,
  useGetV1CollectionsQuery,
  useLazyGetV1CollectionsQuery,
  usePostV1CollectionsMutation,
  useDeleteV1CollectionsByCollectionIdMutation,
  useGetV1CollectionsByCollectionIdQuery,
  useLazyGetV1CollectionsByCollectionIdQuery,
  usePutV1CollectionsByCollectionIdMutation,
  useGetV1CustomersQuery,
  useLazyGetV1CustomersQuery,
  usePostV1CustomersMutation,
  useGetV1CustomersAllQuery,
  useLazyGetV1CustomersAllQuery,
  useGetV1CustomersCompanyByCompanyNameQuery,
  useLazyGetV1CustomersCompanyByCompanyNameQuery,
  usePostV1CustomersFirebaseMutation,
  useDeleteV1CustomersByCustomerIdMutation,
  useGetV1CustomersByCustomerIdQuery,
  useLazyGetV1CustomersByCustomerIdQuery,
  usePatchV1CustomersByCustomerIdMutation,
  useDeleteV1ExtensionsMutation,
  useGetV1ExtensionsQuery,
  useLazyGetV1ExtensionsQuery,
  usePostV1ExtensionsMutation,
  usePutV1ExtensionsMutation,
  useGetV1ExtensionsDownloadBySpecialObjectNameQuery,
  useLazyGetV1ExtensionsDownloadBySpecialObjectNameQuery,
  usePostV1ExtensionsPublishMutation,
  useGetV1ExtensionsTemplatesQuery,
  useLazyGetV1ExtensionsTemplatesQuery,
  usePostV1LoginMutation,
  useGetV1SourcesQuery,
  useLazyGetV1SourcesQuery,
  usePostV1SourcesDocumentMutation,
  usePutV1SourcesDocumentBySourceIdMutation,
  usePostV1SourcesSuggestionMutation,
  usePutV1SourcesSuggestionBySourceIdMutation,
  usePostV1SourcesWebsiteMutation,
  useGetV1SourcesWebsiteCrawlQuery,
  useLazyGetV1SourcesWebsiteCrawlQuery,
  usePostV1SourcesWebsiteCrawlMutation,
  useGetV1SourcesWebsiteCrawlClearByJobIdQuery,
  useLazyGetV1SourcesWebsiteCrawlClearByJobIdQuery,
  usePostV1SourcesWebsiteCrawlConsumeMutation,
  useGetV1SourcesWebsiteCrawlProgressByJobIdQuery,
  useLazyGetV1SourcesWebsiteCrawlProgressByJobIdQuery,
  usePostV1SourcesWebsiteScrapeMutation,
  usePostV1SourcesWebsitesMutation,
  useGetV1SourcesWebsitesScrapeQuery,
  useLazyGetV1SourcesWebsitesScrapeQuery,
  useDeleteV1SourcesBySourceIdMutation,
  useGetV1SourcesBySourceIdQuery,
  useLazyGetV1SourcesBySourceIdQuery,
  useGetV1SourcesBySourceIdDownloadQuery,
  useLazyGetV1SourcesBySourceIdDownloadQuery,
  usePostV1TestMutation,
  usePostV1TestBucketMutation,
} = injectedRtkApi;
