import ModalDialog from "../../../../shared/ModalDialog";
import * as React from "react";
import Form, { Field } from "../../../../shared/Form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../state/store";
import { setAddSourceModalOpen } from "../../../../state/sources/addSourceModalOpenSlice";
import { FieldValues } from "react-hook-form";
import {
  layerApi,
  SuggestionSourceCreate,
  useGetV1SourcesWebsiteCrawlQuery,
  useGetV1SourcesWebsitesScrapeQuery,
  useLazyGetV1SourcesWebsiteCrawlClearByJobIdQuery,
  useLazyGetV1SourcesWebsiteCrawlProgressByJobIdQuery,
  usePostV1SourcesDocumentMutation,
  usePostV1SourcesSuggestionMutation,
  usePostV1SourcesWebsiteCrawlMutation,
  usePostV1SourcesWebsiteMutation,
  WebsiteSourceCreate,
} from "../../../../state/layerApi";
import SelectWebsiteSourcesTable from "./SelectWebsiteSourcesTable";
import { Box } from "@mui/material";
import { useSnackbarContext } from "../../../../contexts/SnackbarContext";
import CrawlStatus from "../CrawlStatus";
import ScrapeStatus from "../ScrapeStatus";

export default function AddSourceModal() {
  // TODO: Front end validation? Or handle error messages from back end?
  // TODO: add collections
  const ADD_SOURCE_FIELDS: Field[] = [
    // Common Fields
    {
      fieldName: "type",
      fieldLabel: "Source Type",
      fieldType: "select",
      fieldOptions: [
        { id: "document", label: "Document" },
        { id: "website", label: "Website" },
        { id: "suggestion", label: "Suggestion" },
      ],
      fieldRequired: true,
      fieldOnChange: (value: string) => {
        setSelectedType(value);
      },
    },
    {
      fieldName: "name",
      fieldLabel: "Name",
      fieldType: "text",
      fieldRequired: true,
    },
    // Document Sources
    {
      fieldName: "file",
      fieldLabel: "Document",
      fieldType: "file",
      fieldRequired: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "document",
      fieldAcceptedFileTypes: ["pdf", "markdown", "txt", "markdown"],
    },
    // Website Sources
    {
      fieldName: "url",
      fieldLabel: "Website",
      fieldType: "text",
      fieldRequired: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "website",
    },
    {
      fieldName: "crawlSetting",
      fieldLabel: "Crawl Setting",
      fieldType: "select",
      fieldOptions: [
        { id: "individual", label: "Individual Page" },
        { id: "crawl", label: "Crawl Website" },
      ],
      fieldRequired: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "website",
      fieldHelperText:
        "Select whether to add an individual page or crawl the website",
      fieldOnChange: (value: string) => {
        if (value === "crawl") {
          setCrawlSite(true);
        } else {
          setCrawlSite(false);
        }
      },
    },
    {
      fieldName: "crawlFilters",
      fieldLabel: "Crawl Filters",
      fieldType: "autocomplete",
      fieldOptions: [],
      fieldRequired: false,
      fieldDependentOnName: "crawlSetting",
      fieldDependentValue: "crawl",
      fieldHelperText:
        "Enter patterns to match URLs to crawl (e.g. https://example.com/docs/*). Press enter after each filter. Leave blank to crawl all URLs.",
      multiple: true,
      freeSolo: true,
    },
    // Suggestion Sources
    {
      fieldName: "question",
      fieldLabel: "Question",
      fieldType: "text",
      fieldRequired: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "suggestion",
    },
    {
      fieldName: "answer",
      fieldLabel: "Answer",
      fieldType: "text",
      fieldRequired: true,
      fieldDependentOnName: "type",
      fieldDependentValue: "suggestion",
    },
  ];

  const dispatch = useDispatch<AppDispatch>();
  // TODO: convert to specific selector
  const addSourceModalOpen = useSelector(
    (state: RootState) => state.addSourceModalOpen.open,
  );

  const [selectedType, setSelectedType] = React.useState("");
  const [crawlSite, setCrawlSite] = React.useState(false);
  const [crawlSiteSubmitted, setCrawlSiteSubmitted] = React.useState(false);
  const [crawledSites, setCrawledSites] = React.useState<string[]>([]);

  const [postCrawlSite, { isLoading: isCrawlSiteLoading }] =
    usePostV1SourcesWebsiteCrawlMutation();
  const [postDocumentSource, { isLoading: isDocumentLoading }] =
    usePostV1SourcesDocumentMutation();
  const [postWebsiteSource, { isLoading: isWebsiteLoading }] =
    usePostV1SourcesWebsiteMutation();
  const [postSuggestionSource, { isLoading: isSuggestionLoading }] =
    usePostV1SourcesSuggestionMutation();

  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const { addMessage } = useSnackbarContext();

  const { data: allCrawlsResponse } = useGetV1SourcesWebsiteCrawlQuery();
  const [getCrawlProgress] =
    useLazyGetV1SourcesWebsiteCrawlProgressByJobIdQuery();
  const [clearCrawlJob] = useLazyGetV1SourcesWebsiteCrawlClearByJobIdQuery();
  const [crawlIds, setCrawlIds] = React.useState<string[]>([]);

  const [pollingInterval, setPollingInterval] = React.useState<number>(5000);
  const [scrapeOpen, setScrapeOpen] = React.useState<boolean>(false);
  const [prevScrapeCount, setPrevScrapeCount] = React.useState<number>(0);
  const { data: scrapeCountData } = useGetV1SourcesWebsitesScrapeQuery(
    undefined,
    {
      pollingInterval,
      skipPollingIfUnfocused: true,
    },
  );

  // Crawl code

  React.useEffect(() => {
    setCrawlIds(
      allCrawlsResponse === undefined ? [] : allCrawlsResponse.job_ids,
    );
  }, [allCrawlsResponse, setCrawlIds]);

  const removeCrawlId = (id: string) => {
    setCrawlIds((prevCrawlIds) => {
      const index = prevCrawlIds.indexOf(id);
      const nextCrawlIds = [...prevCrawlIds];
      if (index > -1) {
        nextCrawlIds.splice(index, 1);
      }
      return nextCrawlIds;
    });
  };

  const onCloseCrawl = async (id: string) => {
    return clearCrawlJob({ jobId: id })
      .unwrap()
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        removeCrawlId(id);
      });
  };

  const onErrorCrawl = (id: string) => {
    onCloseCrawl(id).then(() => {
      addMessage("Error crawling website", "error", 60);
    });
  };

  const onDoneCrawl = (id: string) => {
    getCrawlProgress({ jobId: id })
      .unwrap()
      .then((response) => {
        setCrawledSites(response.successful_urls);
        setCrawlSiteSubmitted(true);
        return onCloseCrawl(id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    if (crawlSiteSubmitted && crawledSites.length > 0) {
      dispatch(setAddSourceModalOpen(true));
    }
  }, [crawlSiteSubmitted, crawledSites, dispatch]);

  // Scrape code

  React.useEffect(() => {
    if (scrapeCountData === undefined) {
      setScrapeOpen(false);
      return;
    }

    setPrevScrapeCount((prevScrapeCount) => {
      if (prevScrapeCount === scrapeCountData.active) return prevScrapeCount;
      if (prevScrapeCount > 0 && scrapeCountData.active === 0)
        addMessage("Sources Added", "info", 60);
      dispatch(layerApi.util.invalidateTags(["Sources"]));
      return scrapeCountData.active;
    });

    if (scrapeCountData.active === 0) {
      if (pollingInterval === 0) return;
      setPollingInterval(0);
      setScrapeOpen(false);
    } else {
      if (pollingInterval > 0) return;
      setPollingInterval(3000);
      setScrapeOpen(true);
    }
  }, [
    scrapeCountData,
    setScrapeOpen,
    setPrevScrapeCount,
    dispatch,
    pollingInterval,
    setPollingInterval,
    addMessage,
  ]);

  const scrapeMessage = scrapeCountData?.active
    ? `${scrapeCountData.active} Remaining`
    : "Sources Added";

  // Code that still needs refactored

  const handleClose = () => {
    dispatch(setAddSourceModalOpen(false));
    setCrawlSiteSubmitted(false);
    setCrawlSite(false);
  };

  const handleFormSubmit = (values: FieldValues) => {
    setCrawlSiteSubmitted(false);
    const sourceType = values.type;
    switch (sourceType) {
      case "document":
        const formData = new FormData();
        formData.set("name", values.name);
        formData.set("file", values.file);

        postDocumentSource({
          // @ts-ignore
          documentSourceCreate: formData,
        }).then(() => {
          addMessage("Source Added", "info", 60);
          handleClose();
        });
        break;
      case "website":
        if (!crawlSite) {
          postWebsiteSource({
            websiteSourceCreate: values as WebsiteSourceCreate,
          }).then(() => {
            addMessage("Source Added", "info", 60);
            handleClose();
          });
        } else {
          postCrawlSite({
            url: values.url as string,
            webCrawlFilterInput: { url_filters: values.crawlFilters || null },
          })
            .then((result) => {
              if ("data" in result) {
                if (result.data.job_id) {
                  dispatch(setAddSourceModalOpen(false));
                  setPollingInterval(3000);
                } else {
                  throw new Error(
                    `Unexpected response: ${JSON.stringify(result.data)}`,
                  );
                }
              } else if ("error" in result) {
                throw new Error(
                  `Error response: ${JSON.stringify(result.error)}`,
                );
              }
            })
            .catch((err) => {
              console.error(err);
              addMessage("Unable to crawl website", "error", 60);
            });
        }
        break;
      case "suggestion":
        postSuggestionSource({
          suggestionSourceCreate: values as SuggestionSourceCreate,
        }).then(() => {
          addMessage("Source Added", "info", 60);
          handleClose();
        });
        break;
    }
  };

  return (
    <>
      <ModalDialog
        label={!crawlSiteSubmitted ? "Add Source" : "Select Website Sources"}
        modalOpen={addSourceModalOpen}
        handleClose={handleClose}
        defaultWidth={!crawlSiteSubmitted ? 400 : 600}
      >
        <Box sx={{ display: !crawlSiteSubmitted ? "block" : "none" }}>
          <Form
            fields={ADD_SOURCE_FIELDS}
            handleFormSubmit={handleFormSubmit}
            submitButtonLabel={
              crawlSite && selectedType === "website"
                ? "Crawl Website"
                : "Add Source"
            }
            submitButtonLoading={[
              isDocumentLoading,
              isWebsiteLoading,
              isSuggestionLoading,
              isCrawlSiteLoading,
            ].some((v) => v)}
          />
        </Box>
        {crawlSiteSubmitted && (
          <SelectWebsiteSourcesTable
            crawledSites={crawledSites}
            handleClose={handleClose}
          />
        )}
      </ModalDialog>
      <ScrapeStatus open={scrapeOpen} message={scrapeMessage} />
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          display: "flex",
          flexDirection: "column-reverse",
          gap: 1,
          color: darkMode ? "#333" : "#fff",
        }}
      >
        {crawlIds.map((crawlId) => (
          <CrawlStatus
            key={crawlId}
            id={crawlId}
            onError={() => onErrorCrawl(crawlId)}
            onDoneButton={() => onDoneCrawl(crawlId)}
            onCloseButton={() => onCloseCrawl(crawlId)}
          />
        ))}
      </Box>
    </>
  );
}
